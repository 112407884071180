/* VARIABLES */
:root {
  --soft-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);
  --shadow-soft: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);
  --shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.25);
  --hard-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);
  --shadow-hard: 2px 2px 10px -2px rgba(0, 0, 0, 0.1);

  --gap-small: 5px;
  --gap: 10px;
  --gap-large: 20px;

  --round-small: 6px;
  --round: 12px;
  --round-large: 20px;

  --breakpoint: 600px;

  --color-primary: #000;
  --color-soft: #e6f8f2;
  --color-a: #00c2f5;
  --color-b: #ffc84b;
  --color-c: #e5526e;
}

@media all and (min-width: 600px) {
  :root {
    --gap-small: 10px;
    --gap: 20px;
    --gap-large: 30px;
  }
}

/* RESET */

/*
  Francisco's CSS Reset - For CRA copy/paste it in your `index.css`
  But it's basically just Josh's Custom CSS Reset + a bit of the CRA defaults
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* CONTROVERSIAL - Use at your own risk; for webapps it's prob a good default */
* {
  margin: 0;
}

html,
body {
  background: #fff;
  line-height: 1.5;
  font-size: 16px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noscroll {
  overflow: hidden;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
  height: 100%;
}
